<template>
<div class="margin-t-70">
    <Navbar/>
    <Testimonial/>
    <Footer/>
</div>
</template>

<script>
import Navbar from "@/components/navbar-stable";
import Footer from "@/components/footer";
import Testimonial from "@/components/testimonial";


export default {
    components : {
        Navbar,
        Footer,
        Testimonial
    }
}
</script>

<style>

</style>